import { Component } from 'react'
import { isEmpty, isNil } from 'lodash'

class Page extends Component {
  static async serverSideCall(ctx) {
    const { rootStore } = ctx

    const { authStore, resumesStore } = rootStore
    const { isLoggedIn } = authStore
    let hasResume = false

    if (isLoggedIn) {
      let { resume } = resumesStore

      if (isEmpty(resume)) {
        resume = await resumesStore.refreshResume()
      }

      hasResume = resume ? !isNil(resume.id) : false
    }

    return {
      isLoggedIn,
      hasResume,
    }
  }
}

export default Page
