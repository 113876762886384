import parseDetails from '~/presenters/detailsParser'
import splitAndCleanup from '~/presenters/stringSplitter'
import { orderBy } from 'lodash'

const linkedInResumeParser = (linkedInResume) => { // todo: Rename to have verb at begining of function
  const summary = linkedInResume.basics.summary || ''

  const accomplishments = {
    publications: linkedInResume.publications || [],
    patents: linkedInResume.patents || [],
    courses: linkedInResume.courses || [],
    projects: linkedInResume.projects || [],
    awards: linkedInResume.awards || [],
    scores: linkedInResume.scores || [],
    languages: linkedInResume.languages || [],
    organizations: linkedInResume.organizations || [],
  }

  const volunteering = (linkedInResume.volunteer || []).map(volunteer => {
    const volunteerObject = {
      name: volunteer.organization,
      ...volunteer,
      volunteer: true,
    }

    delete volunteerObject.organization
    return volunteerObject
  })

  const experience = orderBy([
    ...linkedInResume.work || [],
    ...volunteering,
  ], 'startDate')

  const education = linkedInResume.education || []

  const certifications = linkedInResume.certifications || []

  const skills = linkedInResume.skills || []

  return {
    summary: parseDetails(splitAndCleanup(summary), 'summary'),
    accomplishments: parseDetails(accomplishments, 'accomplishments'),
    experience: parseDetails(experience, 'experience'),
    education: parseDetails(education, 'education'),
    certifications: parseDetails(certifications, 'certifications'),
    skills: parseDetails(skills, 'skills'),
  }
}

export default linkedInResumeParser
