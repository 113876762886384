const ALL_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

export const randomString = (length = 10) => {
  let result = ''
  const charactersLength = ALL_CHARS.length
  for (let i = 0; i < length; i++) {
    result = result + ALL_CHARS.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const detailIdGenerator = (section = 'unknown') => `detail_${section}-${randomString(15)}`
