import React, { useEffect, useState } from 'react'
import Page from '~/pages/page'
import { inject, observer } from 'mobx-react'
import { withTranslation } from '~/i18n'

import GridLayout from '~/layouts/Grid'
import { Button, Modal } from '~/components/common'
import LoaderFullScreen from '~/components/LoaderFullScreen'
import ButtonLinkedIn from '~/components/ButtonLinkedIn'
import FileUploader from '~/components/FileUploader'
import GetStartedModal from '~/components/GetStartedModal'
import useScrollPosition from '~/hooks/useScrollPosition'
import useOnScroll from '~/hooks/useOnScroll'
import { IsDevelopment } from '~/resources/utils'
import linkedInResumeParser from '~/presenters/linkedInResumeParser'
import { URL_BASE } from '~/global.config'
import { isEmpty } from 'lodash'
import { router } from '~/lib/router'

import resumeModifiedFixture from '~/fixtures/samples/resume-modified.json'

import * as style from './styles'

const RESUME_IMAGES = [
  7,
  8,
  9,
  10,
  // Tail images are placed at the head to give the illusion of an infinite loop
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
]

const UploadModal = ({ isOpen, onConfirm, onClose, isUploading, error }) => {
  const [cv, setCv] = useState({})

  return (
    <Modal
      isOpen={isOpen}
      onHide={onClose}
      overflow={true}
      size='sm'
    >
      <Modal.Header>
        <Modal.Title>
          Upload your resume
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ul>
          <li>Please use Word Document</li>
          <li>Try not to use PDF Files</li>
          <li>Please don&apos;t use styling ( columns, etc.. )</li>
          <li>
            Accepted file types are:
            <ul>
              <li><code>.doc</code></li>
              <li><code>.docx</code></li>
              <li><code>.pdf</code></li>
            </ul>
          </li>
        </ul>
        <FileUploader
          onChange={setCv}
        />
        {
          error && (
            <div css={style.uploadModalError}>
              Error: {error}
            </div>
          )
        }
      </Modal.Body>
      <Modal.Footer>
        <Button.Group>
          <Button
            block
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            block
            theme='dark'
            onClick={() => onConfirm(cv)}
            isDisabled={isEmpty(cv)}
            isLoading={isUploading}
          >
            Upload
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
  )
}

const UnderDevelopmentModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onHide={onClose}
      overflow={true}
      size='sm'
    >
      <Modal.Header>
        <Modal.Title>
          Under Development
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        We&apos;re sorry. The linkedIn login feature is currently under development.
      </Modal.Body>
    </Modal>
  )
}

const ResumeCarousel = () => (
  <div css={style.carouselWrapper}>
    <div css={style.carouselContainer}>
      {
        RESUME_IMAGES.map((imageIndex, index) => (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`carousel_image_container-${imageIndex}-${index}`}
            css={style.carouselImageContainer}
          >
            <img
              src={`/static/resumes/${imageIndex}.jpeg`}
              css={style.imageResume}
            />
            <img
              src={`/static/portraits/${imageIndex}.jpg`}
              css={style.imagePortrait}
            />
          </div>
        ))
      }
    </div>
  </div>
)

const IndexPage = ({ rootStore, linkedInUser, isAnton }) => {
  const [isGettingStartedModalOpen, setIsGettingStartedModalOpen] = useState(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)
  const [isUnderDevelopmentModalOpen, setIsUnderDevelopmentModalOpen] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [uploadError, setUploadError] = useState(null)
  const { authStore, resumesStore } = rootStore

  const onGetStartedClick = () => {
    resumesStore.clearStore()
    setIsGettingStartedModalOpen(true)
  }

  const uploadTestCV = async () => {
    await authStore.generateDummyToken()
    setIsGettingStartedModalOpen(false)
    resumesStore.createAndWait({
      data: linkedInResumeParser(resumeModifiedFixture),
      redirectTo: router.routes.scores,
    })
  }

  useEffect(() => {
    const hasResume = !isEmpty(resumesStore.resume)
    const isLoggedIn = authStore.isLoggedIn

    if (hasResume) {
      console.log(resumesStore.resume)
    }

    if (isLoggedIn && hasResume) {
      router.push(router.routes.scores, {})
      return
    }

    if (isLoggedIn && !hasResume) {
      setIsGettingStartedModalOpen(true)
    }
  },
  [authStore.isLoggedIn]
  )

  useEffect(() => {
    if (!isEmpty(linkedInUser)) {
      if (isAnton) {
        uploadTestCV()
      } else {
        setIsUnderDevelopmentModalOpen(true)
      }
    }
  }, [linkedInUser])

  const uploadCV = async (file) => {
    setIsUploading(true)
    const resumePost = await resumesStore.uploadResume({ file })

    if (resumePost.success) {
      setIsUploadModalOpen(false)
      router.push(router.routes.review)
    } else {
      setUploadError(resumePost.data.message)
      setIsUploading(false)
    }
  }

  const handleClickUploadResume = () => {
    setIsUploadModalOpen(true)
  }

  const Content = () => (
    <div css={style.contentContainer}>
      <h1>Enhance your resume with our Artificial Intelligence services.</h1>
      <h2>Enhance your resume using our AI assistant to help land you the job you deserve!</h2>
      <div>
        <Button
          theme='dark'
          onClick={onGetStartedClick}
        >
          Get Started
        </Button>
      </div>
    </div>
  )

  return (
    <div css={style.homePage}>
      <GetStartedModal
        isOpen={isGettingStartedModalOpen}
        onClose={() => setIsGettingStartedModalOpen(false)}
        onUploadTextCV={uploadTestCV}
        onClickUpload={handleClickUploadResume}
      />
      <UploadModal
        isOpen={isUploadModalOpen}
        onConfirm={uploadCV}
        isUploading={isUploading}
        onClose={() => setIsUploadModalOpen(false)}
        error={uploadError}
      />
      <UnderDevelopmentModal
        isOpen={isUnderDevelopmentModalOpen}
        onClose={() => setIsUnderDevelopmentModalOpen(false)}
      />
      <ResumeCarousel />
      <Content />
    </div>
  )
}

IndexPage.getInitialProps = async (ctx) => {
  const { isLoggedIn, hasResume } = await Page.serverSideCall(ctx)
  const { query, rootStore } = ctx
  let linkedInUser = {}
  let isAnton = false

  if (query.linkedInCode) {
    linkedInUser = await rootStore.authStore.api().getLinkedInProfile({
      code: query.linkedInCode,
      base_url: IsDevelopment ? 'http://localhost:3001' : URL_BASE,
    })

    if (linkedInUser.success) {
      linkedInUser = linkedInUser.data

      isAnton = linkedInUser.localizedFirstName === 'Anton'
    }
  }

  if (isLoggedIn && hasResume && !isAnton) {
    router.push(router.routes.scores, {}, ctx)
  }

  return {
    linkedInUser,
    isAnton,
    namespacesRequired: ['home', 'common'],
  }
}

export default withTranslation()(
  inject('rootStore')(
    observer(IndexPage)
  )
)
