import { css } from '@emotion/react'
import colors from '~/styles/colors'
import { headerHeight } from '~/styles/constants'
import { mqMin, mqMax } from '~/styles/mixins/mediaQueries'

export const grid = ({ isFullPage }) => css`
  display: flex;
  flex-direction: column;
  min-height: ${isFullPage ? '100%' : 'unset'};
  height: ${isFullPage ? `calc(100vh - ${headerHeight})` : '100%'};
  margin: 0 7%;

  ${mqMax.md} {
    margin: 0;
    height: auto;
  }
`

export const row = css`
  height: 100%;
`

export const col = ({ isCentered, isSpread, hasShadow }) => css`
  height: 100%;
  padding: 2rem 2%;
  display: flex;
  flex-direction: column;

  ${isCentered && css`
    justify-content: center;
  `};

  ${isSpread && css`
    justify-content: space-between;
  `};

  ${hasShadow && css`
    ${mqMin.xs} {
      box-shadow: 0rem 1rem 1rem -1rem ${colors.shadow};
    }

    ${mqMin.md} {
      box-shadow: 1rem 0rem 1rem -1rem ${colors.shadow};
    }
  `};

  ${mqMax.md} {
    padding: 1rem 5%;
    /* height: auto; */
  }
`
