import React from 'react'
import PropTypes from 'prop-types'

import { Grid, Row, Col } from 'rsuite'

import * as style from './styles.js'

const GridLayoutCol = ({
  children,
  isCentered,
  isSpread,
  hasShadow,
  ...props
}) => (
  <Col
    css={style.col({ isCentered, isSpread, hasShadow })}
    {...props}
  >
    {children}
  </Col>
)

/**
 * GridLayout
 *
 * @component
 * @usage `import GridLayout from '~/layouts/Standard'`
 * @example
 * // Export the page using the HOC:
 * export default GridLayout(MyPage)
 */
const GridLayout = ({ isFullPage, children, ...extra }) => {
  return (
    <Grid
      fluid
      css={style.grid({ isFullPage })}
      {...extra}
    >
      <Row css={style.row}>
        {children}
      </Row>
    </Grid>
  )
}

GridLayout.Col = GridLayoutCol

GridLayout.propTypes = {
  isFullPage: PropTypes.bool,
}

GridLayout.defaultProps = {
  isFullPage: true,
}


export default GridLayout
