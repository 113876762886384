import { getURLWithQueryParams } from '~/utils/url'
import { URL_BASE } from '~/global.config'
import { IsDevelopment } from '~/resources/utils'

const LINKEDIN_SCOPE = 'r_liteprofile' // ! 'r_fullprofile r_emailaddress'
const LINKEDIN_RIDERECT = IsDevelopment ? 'http://localhost:3001/api/oauth' : `${URL_BASE}/api/oauth`
const LINKEDIN_CLIENT_ID = process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID

const LINKEDIN_STATE = process.env.NEXT_PUBLIC_LINKEDIN_STATE

export const LINKEDIN_URL = getURLWithQueryParams('https://www.linkedin.com/oauth/v2/authorization', {
  response_type: 'code',
  client_id: LINKEDIN_CLIENT_ID,
  redirect_uri: LINKEDIN_RIDERECT,
  state: LINKEDIN_STATE,
  scope: LINKEDIN_SCOPE,
})

// export const fetchAccessToken = async (code) => {
//   const LINKEDIN_URL = getURLWithQueryParams(
//     'https://www.linkedin.com/oauth/v2/accessToken',
//     {
//       grant_type: 'authorization_code',
//       code,
//       redirect_uri: LINKEDIN_RIDERECT,
//       client_id: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID,
//       client_secret: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_SECRET,
//     }
//   )

//   let tokenResp
//   const resp = await fetch(LINKEDIN_URL, {
//     method: 'POST',
//     headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
//   })

//   if (resp.ok) {
//     tokenResp = await resp.json()
//   }

//   return tokenResp
// }

// export const fetchProfile = async (linkedInAccessToken) => {
//   let retrievedUser = {}
//   const userCall = await fetch('https://api.linkedin.com/v2/me', {
//     method: 'GET',
//     headers: { Connection: 'Keep-Alive', Authorization: `Bearer ${linkedInAccessToken}` },
//   })

//   if (userCall.ok) {
//     retrievedUser = await userCall.json()
//   }

//   return retrievedUser
// }
