import React from 'react'
import PropTypes from 'prop-types'

import { Button } from '~/components/common'
import { LINKEDIN_URL } from '~/middleware/linkedIn'

import * as style from './styles'

/**
 * <Describe the ButtonLinkedIn component here>
 *
 * @component
 * @usage import ButtonLinkedIn from '~/components/ButtonLinkedIn'
 * @example
 * <Add an example of how ButtonLinkedIn is used here>
 */
const ButtonLinkedIn = ({ ...extra }) => {
  return (
    <Button
      icon='linkedIn'
      href={LINKEDIN_URL}
      css={style.buttonLinkedIn}
      {...extra}
    >
      From LinkedIn
    </Button>
  )
}

ButtonLinkedIn.propTypes = {
}

ButtonLinkedIn.defaultProps = {
}

export default ButtonLinkedIn
