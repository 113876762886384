import { css } from '@emotion/react'
import colors from '~/styles/colors'
import { transparentize } from 'polished'

export const dropzone = ({ isAccepted, isRejected }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.greyLight};
  min-height: 5rem;
  padding: 1rem 2rem;
  border: 0.2rem dashed ${colors.greyDark};
  border-radius: 0.2rem;
  outline: none;
  color: ${transparentize(0.25, colors.black)};
  cursor: pointer;

  ${isAccepted && `
    background-color: ${transparentize(0.5, colors.green)};
    border-color: ${colors.greenDark};
  `};

  ${isRejected && `
    background-color: ${transparentize(0.5, colors.red)};
    border-color: ${colors.redDark};
  `};
`

export const file = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const iconFile = css`
  color: ${colors.black};
  font-size: 2.5rem;
`
