import { css } from '@emotion/react'
import colors from '~/styles/colors'
import { mqMax } from '~/styles/mixins/mediaQueries'

export const getStartedTitle = css`
  border-bottom: 0.1rem solid ${colors.greyLight};
  text-align: center;
`

export const getStartedBody = css`
  margin: 1rem 2rem;
  min-height: 5rem;
  text-align: center;

  ${mqMax.md} {
    margin: 1rem;
  }
`

export const buttonGetStarted = css`
  display: block;
  width: 100%;
  margin-top: 1.5rem;
`

export const modalFixer = css`
  display: block;
  width: 100%;
  margin-top: 1.5rem;
`

export const getStartedTerms = css`
  display: block;
  text-align: center;

  > a {
    cursor: pointer;
  }
`
