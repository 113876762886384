import { css, keyframes } from '@emotion/react'
import { headerHeight } from '~/styles/constants'
import colors from '~/styles/colors'
import { mqMin, mqMax } from '~/styles/mixins/mediaQueries'
import { zIndexes } from '~/styles/constants'
import { transparentize } from 'polished'

const INTRO_TOP_SIZE = 35 // %
const MOBILE_URL_BAR_HEIGHT = '3.5rem'

export const homePage = css`
  position: relative;
  height: calc(100vh - ${headerHeight});
  overflow: hidden;
  background: linear-gradient(0deg, ${colors.landingPageDarkBlue} 0%, ${colors.landingPageLightBlue} 100%);
  padding: 0 10%;
  color: ${colors.white};
  ${mqMax.md} {
    overflow: scroll;
  }
`

export const uploadModalError = css`
  color: ${colors.red};
  margin-top: 1rem;
`

const animationImageSliding = keyframes`
  0% {
    transform: translateX(-100vw);
  }

  100% {
    transform: translateX(142.5vw);
  }
`

export const carouselWrapper = css`
  position: absolute;
  bottom: 0;
  left: 0;
  transform: rotate(-25deg);
  width: 100%;

  ${mqMax.sm} {
    bottom: unset;
    top: 70%;
  }

  ${mqMax.md} {
    display: none;
  }
`

export const carouselContainer = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 20rem;
  animation: ${animationImageSliding} linear infinite;
  animation-duration: 60s;

  ${mqMax.sm} {
    animation-duration: 30s;
  }

  > div {
    flex: 1;
    height: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem;
  }
`

export const carouselImageContainer = css`
  position: relative;
  transform: skew(40deg, 0deg);
  transition: 0.2s ease-in-out;

  > img {
    image-rendering: -webkit-optimize-contrast;
  }

  &:hover {
    transform: skew(40deg, 0deg) translate(0rem, -3rem);
  }
`

export const imageResume = css`
  height: 100%;
  box-shadow: 0.5rem 1rem 0.7rem 0rem ${transparentize(0.85, colors.black)};
`

export const imagePortrait = css`
  position: absolute;
  top: -2rem;
  right: 3rem;
  width: 6rem;
  box-shadow: -1rem 1rem 0.4rem 0rem ${transparentize(0.7, colors.black)};
`

export const contentContainer = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 10%;
  width: 70%;

  ${mqMax.md} {
    width: 100%;
    text-align: center;
    left: 0;
    padding: 0 5%;
    padding-bottom: 3rem;
  }

  > h1 {
    margin-top: 3rem;
    margin-bottom: 2rem;

    ${mqMin.xs} {
      font-size: 2rem;
      line-height: 3rem;
    }

    ${mqMin.sm} {
      font-size: 3rem;
      line-height: 3rem;
    }

    ${mqMin.md} {
      font-size: 3rem;
      line-height: 3.0rem;
      margin-bottom: 3rem;
    }
  }

  > h2 {
    font-size: 1.4rem;
    font-weight: normal;
    line-height: 2rem;
    width: 75%;
    margin-bottom: 3rem;

    ${mqMax.sm} {
      width: 90%;
      font-size: 1.2rem;
      line-height: 1.6rem;
      margin: auto;
      margin-bottom: 2rem;
    }
  }
`
