import React from 'react'
import PropTypes from 'prop-types'

import { useDropzone } from 'react-dropzone'
import { Icon } from '~/components/common'
import { isEmpty } from 'lodash'

import * as style from './styles'

// ! https://github.com/react-dropzone/react-dropzone/issues/991
const ACCEPTED_TYPES = [
  // ? Legacy browser support that required extensions
  '.doc',
  '.docx',
  '.pdf',
  // ? Modern browsers that use MIME types
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/pdf', // .pdf
]

/**
 * <Describe the FileUploader component here>
 *
 * @component
 * @usage import FileUploader from '~/components/FileUploader'
 * @example
 * <Add an example of how FileUploader is used here>
 */
const FileUploader = ({ onChange }) => {
  const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
    multiple: false,
    accept: ACCEPTED_TYPES.join(', '),
    onDropAccepted: file => onChange(file[0]),
  })

  const Files = () => {
    return (
      <div>
        {
          acceptedFiles.map(file => {
            return (
              <div
                key={`file_${file.path}`}
                css={style.file}
              >
                <Icon
                  icon='file'
                  css={style.iconFile}
                />
                <span>
                  {file.path}
                </span>
              </div>
            )
          })
        }
      </div>
    )
  }

  return (
    <section>
      <div
        {...getRootProps({
          css: style.dropzone({ isAccepted: isDragAccept, isRejected: isDragReject }),
        })}
      >
        <input {...getInputProps()} />
        {
          isEmpty(acceptedFiles)
            ? <p>Drop your file here, or click to select your file.</p>
            : <Files />
        }
      </div>
    </section>
  )
}

FileUploader.propTypes = {
  onChange: PropTypes.func,
}

FileUploader.defaultProps = {
  onChange: () => {},
}

export default FileUploader
