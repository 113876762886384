import React from 'react'
import PropTypes from 'prop-types'

import { Modal, Button } from '~/components/common'
import ButtonLinkedIn from '~/components/ButtonLinkedIn'
import { IsDevelopment } from '~/resources/utils'
import { router } from '~/lib/router'

import * as style from './styles'

/**
 * <Describe the GetStartedModal component here>
 *
 * @component
 * @usage import GetStartedModal from '~/components/GetStartedModal'
 * @example
 * <Add an example of how GetStartedModal is used here>
 */
const GetStartedModal = ({ isOpen, onClose, onUploadTextCV, onClickUpload }) => {
  return (
    <Modal
      isOpen={isOpen}
      onHide={onClose}
      overflow={false}
      size='sm'
    >
      <Modal.Header>
        <Modal.Title>
          Get Started
        </Modal.Title>
        <Modal.Body>
          <div>
            <h4 css={style.getStartedTitle}>Import a Resume</h4>
          </div>
          <div css={style.getStartedBody}>
            <span>
              As easy as one-two! We&apos;ll take your resume to the next level.
              Start by importing your resume
              {/* Start by importing your resume from LinkedIn, or uploading it from your computer. */}
            </span>
            <div css={style.modalFixer}></div>
            {/* <ButtonLinkedIn */}
            {/*   css={style.buttonGetStarted} */}
            {/* /> */}
            {
              IsDevelopment && (
                <Button
                  css={style.buttonGetStarted}
                  onClick={onUploadTextCV}
                >
                  [TEST] Anton's Resume
                </Button>
              )
            }
            <Button
              icon='upload'
              css={style.buttonGetStarted}
              onClick={onClickUpload}
            >
              Upload
            </Button>
          </div>
          <div>
            <h4 css={style.getStartedTitle}>Don&apos;t Have a Resume?</h4>
            <div css={style.getStartedBody}>
              <span>
                No problem! We&apos;ll help you write one!
              </span>
              <Button
                css={style.buttonGetStarted}
                href={router.routes.review}
              >
              Write My Resume
              </Button>
            </div>
          </div>
          <div>
            <small css={style.getStartedTerms}>
              By continuing, you agree to our <a>Terms and Conditions</a> and our <a>Privacy Policy</a>
            </small>
          </div>
        </Modal.Body>
      </Modal.Header>
    </Modal>
  )
}

GetStartedModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onUploadTextCV: PropTypes.func,
  onClickUpload: PropTypes.func,
}

GetStartedModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  onUploadTextCV: () => {},
  onClickUpload: () => {},
}

export default GetStartedModal
