import { isString, camelCase } from 'lodash'
import { randomString, detailIdGenerator } from '~/lib/random'
import splitAndCleanup from '~/presenters/stringSplitter'

const parseText = (string, section, sectionOverride) => {
  return splitAndCleanup(string).map(datum => {
    return {
      id: detailIdGenerator(sectionOverride || section),
      text: datum,
      children: [],
    }
  })
}

const parseSummaryDetails = (details, section) => {
  // if (isString(details)) {
  //   return [
  //     { id: detailIdGenerator(section), text: details },
  //   ]
  // }

  return details.map(detail => {
    return {
      id: detailIdGenerator(section),
      text: detail,
      children: [],
    }
  })
}

const parseAccomplishmentsDetails = (details, section) => {
  // if (isString(detail)) {
  //   return {
  //     id: detailIdGenerator(section),
  //     text: detail,
  //   }
  // }

  // return [ // todo
  //   { id: detailIdGenerator(section), text: details },
  // ]
}

const parseCertificationsDetails = (details, section) => {
  // if (isString(detail)) {
  //   return {
  //     id: detailIdGenerator(section),
  //     text: detail,
  //   }
  // }

  // return [ // todo
  //   { id: detailIdGenerator(section), text: details },
  // ]
}

const parseEducationDetails = (details, section) => {
  return details.map(detail => {
    if (isString(detail)) {
      return {
        id: detailIdGenerator(section),
        text: detail,
        children: [],
      }
    }

    const { area, gpa, institution, studyType, startDate, endDate } = detail

    return {
      id: detailIdGenerator(section),
      text: `${area} (${studyType})${gpa ? ` GPA: ${gpa}` : ''}\n${institution}\n(${startDate} - ${endDate})`,
      children: [],
    }
  })
}

const parseExperienceDetails = (details, section) => {
  return details.map(detail => {
    if (isString(detail)) {
      return {
        id: detailIdGenerator(section),
        text: detail,
        children: [],
      }
    }

    const { name, position, summary, url, startDate, endDate } = detail

    return {
      id: `job-${camelCase(name)}-${randomString(5)}`,
      employer: name,
      // texts: [
      //   {
      //     id: detailIdGenerator(section),
      //     text: `${position}\n(${startDate} - ${endDate})${url ? ` (${url})` : ''}\n${summary}`,
      //   },
      // ],
      position,
      url,
      startDate,
      endDate,
      texts: parseText(summary, section),
    }
  })
}

const parseSkillsDetails = (details, section) => {
  return details.map(detail => {
    if (isString(detail)) {
      return {
        id: detailIdGenerator(section),
        text: detail,
        children: [],
      }
    }

    const { name, level } = detail

    return {
      id: detailIdGenerator(section),
      text: `${name}${level ? ` Level: ${level}` : ''}`,
      children: [],
    }
  })
}

const parseFunctions = {
  // Main
  summary: parseSummaryDetails,
  accomplishments: parseAccomplishmentsDetails,
  certifications: parseCertificationsDetails,
  education: parseEducationDetails,
  experience: parseExperienceDetails,
  skills: parseSkillsDetails,
  // Additional
  text: parseText,
}

const parseDetails = (details, section, sectionOverride) => {
  return parseFunctions[section](details, section, sectionOverride)
}

export default parseDetails
